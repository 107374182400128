import React from 'react';
import { Section, RichText, Button, Image } from 'components';

import './styles.scss';

const Wysiwyg = (props) => {
  const { data, location } = props;
  const { primary } = data;
  const {
    content,
    title,
    background_color: backgroundColor,
    image,
    primary_button_link: primaryLink,
    primary_button_text: primaryText,
    secondary_button_link: secondaryLink,
    secondary_button_text: secondaryText,
    text_alignment: isTextCentered,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
  } = primary;

  const contentHtml = content?.html;
  const titleHtml = title?.html;

  const isBlog = location?.pathname.includes('blog') ? 'blog' : '';

  return (
    <Section
      sliceName="wysiwygSlice"
      className={`${isBlog} wysiwyg ${isTextCentered && 'centerText'}`}
      backgroundColor={backgroundColor}
      containerClassName="wrapper"
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      <div className="textContainer">
        {titleHtml && <RichText html={titleHtml} className="wysiwyg-title" h2 />}
        {contentHtml && <RichText html={contentHtml} className="wysiwyg-content" p2 />}
        {primaryLink?.url && primaryText?.text && secondaryLink?.url && secondaryText?.text && (
          <div className="buttonsContainer">
            {primaryLink?.url && primaryText?.text && (
              <Button theme="primary" removeArrows className="primaryButton" to={primaryLink?.url}>
                {primaryText?.text}
              </Button>
            )}
            {secondaryLink?.url && secondaryText?.text && (
              <Button className="secondaryButton" to={secondaryLink?.url}>
                {secondaryText?.text}
              </Button>
            )}
          </div>
        )}
      </div>
      {image?.url && (
        <div className="imageContainer">
          <Image image={{ url: image?.url }} />
        </div>
      )}
    </Section>
  );
};

export default Wysiwyg;
