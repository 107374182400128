import React from 'react';
import OnVisible from 'react-on-visible';
import { Button } from 'components';
import { getLinkTo } from '../../utils/helpers';
import './styles.scss';

const BreakoutBox = props => {
  const { data } = props;
  const { primary } = data;
  const {
    breakout_content: content,
    breakout_cta_link: ctaLink,
    breakout_title: title,
    breakout_cta_text: ctaText,
  } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const ctaTextText = ctaText?.text;
  const ctaLinkUrl = getLinkTo(ctaLink);

  return (
    <OnVisible className="breakout-box">
      <div className="breakout-box-container">
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: title.html }} className="breakout-box-title"></div>}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: content.html }}></div>}
        {ctaTextText && ctaLinkUrl && (
          <Button to={ctaLink.url} className="button">
            {ctaTextText && <div dangerouslySetInnerHTML={{ __html: ctaText.text }}></div>}
          </Button>
        )}
      </div>
    </OnVisible>
  );
};

export default BreakoutBox;
