import React, { useState } from 'react';
import { Link, Image } from 'components';
import chevron from '../../images/black_chevron.svg';

const Navigation = (props) => {
  const { classNamePrefix = 'main', links } = props;
  const className = classNamePrefix ? `${classNamePrefix}-navigation` : 'navigation';
  // state
  const [showSublinks, setShowSublinks] = useState(false);
  // future logic/state for multiple main links with sublinks
  // const [activeSublinksIndex, setActiveSublinksIndex] = useState(false);

  // toggle sub links for mobile
  const showNavSublinks = (event) => {
    event.preventDefault();
    // setActiveSublinksIndex(index);
    setShowSublinks(!showSublinks);
  };

  const sublinksOpen = showSublinks ? 'active' : '';

  return (
    <nav className={className || ''}>
      {links &&
        links.map((link) => {
          const { text, to, className: navClassName, sublinks, onClick } = link;
          const hasSublinks = sublinks;
          // L I N K   C O N T A I N E R
          return (
            <div
              key={text}
              className={`${className}-link ${navClassName || ''} ${hasSublinks ? 'has-dropdown' : ''} ${sublinksOpen}`}
            >
              {/* M A I N   L I N K */}
              <div className={`${className}-link-text`}>
                <Link to={to} onClick={sublinks ? (event) => showNavSublinks(event) : () => {}}>
                  {text}
                </Link>
                {hasSublinks && (
                  <button type="button" className="header-nav-link-chevron" onClick={(event) => showNavSublinks(event)}>
                    <Image image={{ url: chevron }} />
                  </button>
                )}
              </div>
              {/* S U B L I N K S  C O N T A I N E R */}
              {hasSublinks && (
                <div className={`${className}-sublinks`}>
                  {link.sublinks.map((sublink, index) => {
                    // S U B L I N K
                    const { text: sublinkText, to: sublinkTo, subtext, className: subClassName } = sublink;
                    return (
                      <div
                        key={sublinkText}
                        className={`${className}-sublink ${subClassName || ''}`}
                        style={{ transitionDelay: `${index * 0.1}s` }}
                      >
                        <Link to={sublinkTo} className={`${className}-sublink-link`}>
                          <span className={`${className}-sublink-text`}>{sublinkText}</span>
                          <span className={`${className}-sublink-subtext`}>{subtext}</span>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
    </nav>
  );
};

export default Navigation;
