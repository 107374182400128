import React from 'react';
import { Link } from 'components';

import './styles.scss';

/*
Current themes:
- default: no background color, black text
- primary: black background, white text
- minimal: nothing but text (use with icon e.g. Header 'watch video' button)
- inverted: white text with blue arrow
*/

const Button = (props) => {
  const {
    type,
    to,
    title,
    children,
    theme = 'default',
    outline,
    onClick = () => {},
    className,
    id,
    removeArrows,
  } = props;

  if (!to) {
    return (
      <button
        title={title || ''}
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={onClick}
        className={`button ${theme} ${className} ${outline ? 'outline' : ''}`}
        id={id || null}
      >
        {children}
        {!removeArrows && (
          <div className="button-arrows">
            <span className={`${theme === 'inverted' ? 'arrow-green' : 'arrow'} ${theme === 'play' && 'playIcon'}`} />
          </div>
        )}
      </button>
    );
  }

  return (
    <Link
      to={to}
      title={title || ''}
      onClick={onClick}
      className={`button ${theme} ${className} ${outline ? 'outline' : ''}`}
    >
      {children}
      {!removeArrows && (
        <div className="button-arrows">
          <span className={`${theme === 'inverted' ? 'arrow-green' : 'arrow'} ${theme === 'play' && 'playIcon'}`} />
        </div>
      )}
    </Link>
  );
};

export default Button;
