import React from 'react';
import { Image, Section, Link, Label } from 'components';

import './styles.scss';

const Logos = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    background_color: backgroundColor,
    label,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
    label_color: labelColor,
  } = primary;

  const hasLabel = label?.text;

  return (
    <Section
      sliceName="logosSlice"
      className="logosSliceSection"
      topPadding={topPadding}
      bottomPadding={bottomPadding}
      backgroundColor={backgroundColor}
    >
      <div className="wrapper">
        {hasLabel && <Label className="label" labelHtml={label?.html} theme={labelColor} />}
        {items?.length > 0 && (
          <div className="logosGridContainer">
            {items?.map((item) => {
              const icon = item?.icon;
              const link = item?.link;

              return (
                <Link className="logoItem" to={link?.url} key={Math.random() * 1000}>
                  {icon?.url && <Image image={icon} className="logoImage" />}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Logos;
