import React from 'react';
import { Section, RichText, Label, Button, Link, Image } from 'components';
import arrow from '../../images/arrow.svg';

import './styles.scss';

const Grid = (props) => {
  const { data } = props;
  const { primary, items } = data;

  const {
    background_color: backgroundColor,
    content,
    label,
    title,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
    label_color: labelColor,
  } = primary;

  const labelHtml = label?.html;
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  return (
    <Section
      className="gridSlice"
      containerClassName="wrapper"
      sliceName="gridSlice"
      backgroundColor={backgroundColor}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      <div className="textContainer">
        <Label labelHtml={labelHtml} className="label" theme={labelColor} />
        <RichText html={titleHtml} className="title" h4 />
        <RichText html={contentHtml} className="content" h5 />
      </div>
      <div className="gridContainer">
        {items.map((item, index) => {
          const {
            grid_item_content: itemContent,
            grid_item_title: itemTitle,
            grid_item_button_text: buttonText,
            grid_item_link: link,
          } = item;
          const hasValues = itemContent?.text && itemTitle?.text && link?.url;
          const itemKey = `${item.itemTitle?.text}-${index}`;

          if (!hasValues) return null;

          return (
            <div className="gridItem" key={itemKey}>
              <div className="desktop">
                <div className="itemTitle">{itemTitle?.text}</div>
                <div className="itemContent">{itemContent?.text}</div>
                <Button className="withText" to={link?.url}>
                  {buttonText?.text || 'Learn More'}
                </Button>
              </div>

              <Link className="mobile link" to={link?.url}>
                <div className="itemTitle">{itemTitle?.text}</div>
                <div className="itemContent">{itemContent?.text}</div>
                <Image image={{ url: arrow }} className="arrow" />
              </Link>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default Grid;
