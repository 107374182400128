import React, { useState } from 'react';
import { Link } from 'components';
import './styles.scss';

const SearchBar = ({ small }) => {
  const [inputValue, setInputValue] = useState(null);

  const handleChange = (event) => {
    event.preventDefault();
    setInputValue(event.target.value);
  };

  return (
    <section className={`search-bar ${small ? 'sm' : ''}`}>
      <div className="search-bar-container">
        <input
          type="text"
          placeholder="Your work email"
          className="search-bar-input"
          id="email"
          value={inputValue || ''}
          onChange={(event) => handleChange(event)}
        />
        <Link to="/contact" className="search-bar-button" state={{ email: inputValue }}>
          Get Started
        </Link>
      </div>
    </section>
  );
};

export default SearchBar;
