import React, { useEffect, useRef } from 'react';
import { Section, RichText, Carousel, Link, Image, Label, Button } from 'components';

import './styles.scss';

const BlogPostCarousel = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { background_color: backgroundColor, top_padding: topPadding, bottom_padding: bottomPadding } = primary;
  const customLeftArrowRef = useRef();
  const customDotsRef = useRef();
  const customRightArrowRef = useRef();

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    cssEase: 'linear',
    speed: 300,
    slidesToScroll: 1,
    draggable: true,
    centerMode: false,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    let timer;
    const onResize = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const componentInstances = document.querySelectorAll('.BlogPostCarousel');
        componentInstances.forEach((instance) => {
          const dots = instance.querySelector('.dotsAndArrows');
          const slideContentItems = Array.from(instance.querySelectorAll('.cardContent'));
          const tallestHeight = Math.max(...slideContentItems.map((el) => el.clientHeight));
          dots.style.top = `${tallestHeight - 40}px`;
        });
      }, 100);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const Card = ({ item }) => {
    let label = item?.label?.html;
    let title = item?.title;
    let content = item?.content;
    let image = item?.image;
    let blogLink = item?.link_to_blog_post?.url;
    let buttonText = item?.button_text?.text;
    const labelColor = item?.label_color;

    if (item?.post?.document?.data) {
      label = `<p>${item?.post?.document?.data?.category?.slug}</p>`;
      title = item?.post?.document?.data?.title;
      content = item?.post?.document?.data?.excerpt;
      image = item?.post?.document?.data?.hero_image;
      blogLink = item?.post?.document?.data?.category?.url;
      buttonText = 'Learn More';
    }

    return (
      <div className="card">
        {image?.url && (
          <Link to={blogLink} className="card-image-link">
            <Image image={{ url: image?.url }} className="image" />
          </Link>
        )}
        <div className="cardContent">
          <Label labelHtml={label} theme={labelColor} />
          <RichText html={title?.html} className="title" h2 />
          <RichText html={content?.html} className="content" p1 />
          {blogLink && (
            <Button className="withText" to={blogLink}>
              {buttonText || 'Learn More'}
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <Section
      sliceName="BlogPostCarousel"
      className="section BlogPostCarousel"
      backgroundColor={backgroundColor}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      <div className="wrapper">
        <Carousel
          settings={settings}
          customLeftArrowRef={customLeftArrowRef}
          customRightArrowRef={customRightArrowRef}
          customDotsRef={customDotsRef}
        >
          {items.map((item, i) => {
            const key = `${i}-${item?.title}`;
            return <Card key={key} item={item} />;
          })}
        </Carousel>
        <div className="dotsAndArrows">
          <div className="carouselLeftArrow" ref={customLeftArrowRef} />
          <div className="carouselDots" ref={customDotsRef} />
          <div className="carouselRightArrow" ref={customRightArrowRef} />
        </div>
      </div>
    </Section>
  );
};

export default BlogPostCarousel;
