import React, { useState } from 'react';
import { Image, Link, Section } from 'components';
import arrow from '../../images/down-chevron.svg';

import './styles.scss';

const JobsBoard = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, top_padding: topPadding, bottom_padding: bottomPadding } = primary;

  // state
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [activeLocation, setActiveLocation] = useState('All');

  // content checks
  const titleHtml = title?.html;

  const fields = [];
  const locations = ['All'];

  const locationAbrv = {
    All: 'All',
    London: 'LDN',
    Sydney: 'SYD',
    Vancouver: 'YVR',
    Kyiv: 'KEV',
    'Ho Chi Minh': 'HCM',
  };

  // populate fields
  items.forEach((item) => {
    const { field } = item;
    if (fields.includes(field) === false) {
      fields.push(field);
    }
  });

  // populate locations
  items.forEach((item) => {
    const { location } = item;
    if (locations.includes(location) === false) {
      locations.push(location);
    }
  });

  const handleOnClick = (event, field) => {
    event.preventDefault();
    if (activeField !== field) {
      setActiveField(field);
      setShowDropdown(true);
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  const handleFilter = (event, location) => {
    event.preventDefault();
    if (activeLocation !== location) {
      setActiveLocation(location);
    }
  };

  return (
    <Section className="jobs-board" topPadding={topPadding} bottomPadding={bottomPadding}>
      <div className="wrapper">
        {titleHtml && <div className="jobs-board-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        <div className="jobs-board-filter-container">
          {/* FILTER */}
          <span className="jobs-board-filter-title">Filter by location</span>
          <div className="jobs-board-filter-locations">
            {locations.map((location) => {
              const activeBg = location === activeLocation ? 'active' : '';
              return (
                <button
                  className={`jobs-board-filter-location ${activeBg}`}
                  type="button"
                  onClick={(event) => handleFilter(event, location)}
                >
                  {locationAbrv[location]}
                </button>
              );
            })}
          </div>
        </div>
        {/* ACCORDION */}
        <JobsAccordion
          fields={fields}
          items={items}
          activeField={activeField}
          activeLocation={activeLocation}
          showDropdown={showDropdown}
          handleOnClick={handleOnClick}
        />
      </div>
    </Section>
  );
};

export const JobsAccordion = ({ fields, items, activeField, showDropdown, handleOnClick, activeLocation }) => {
  const dropdownToggle = showDropdown ? 'active' : '';

  return (
    <div className="jobs-board-accordian">
      {fields.map((field) => {
        const filteredJobs = items.filter((item) => {
          const byField = field ? item.field === field : true;
          const byLocation = activeLocation !== 'All' ? item.location === activeLocation : true;
          return byField && byLocation;
        });

        const isActiveField = field === activeField;

        const hideField = filteredJobs.length === 0 ? 'hide' : '';

        return (
          // FIELDS LIST
          <div className={`jobs-board-accordian-section ${isActiveField ? dropdownToggle : ''} ${hideField}`}>
            <button
              type="button"
              className="jobs-board-accordian-field"
              onClick={(event) => handleOnClick(event, field)}
            >
              <span className="button-text">{field}</span>
              <Image image={{ url: arrow }} className={isActiveField && showDropdown ? 'active' : 'inactive'} />
            </button>
            {/* JOBS DROP DOWN */}
            <div className="jobs-board-jobs-container">
              <div className="jobs-board-jobs">
                {filteredJobs.map((job) => {
                  const { apply_link: link, apply_link_text: linkText, location, position } = job;
                  const listingText = filteredJobs.length === 0 ? 'No jobs listed' : '';
                  return (
                    <div className="jobs-board-job">
                      <div className="jobs-board-job-info">
                        <span className="jobs-board-job-location">{listingText || location}</span>
                        <span className="jobs-board-job-position">{position?.text}</span>
                      </div>
                      <Link className="button filled" to={link.url}>
                        {linkText.text}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobsBoard;
