import React from 'react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';

import linkResolver from '../../../config/linkResolver';
import Page from '../../templates/page';

const PrismicPreviewProviderWrapper = props => {
  const { element } = props;
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  );
};

export default PrismicPreviewProviderWrapper;
