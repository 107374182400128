import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import ReactPlayer from 'react-player';
import { Image, Button, Modal, Video, Section } from 'components';
// import playIcon from '../../images/play_button_circled.svg';

import './styles.scss';

const TwoCols = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    object_fit: objectFit,
    image,
    image_left: imageLeft,
    tagline,
    tagline_icon: taglineIcon,
    title,
    video,
    video_image_placeholder: placeholder,
    video_link: youtubeLink,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
    add_video_button: addVideoButton,
  } = primary;

  const youTubeUrl = youtubeLink?.url;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasTaglineIcon = taglineIcon?.url;
  const hasCta = ctaLink.url && ctaText?.text;
  const hasImage = image?.url;
  const hasVideoContent = video?.url;

  // state
  const [showModal, setShowModal] = useState(false);
  // class for video/two cols variant
  const hasVideo = video && video.url;

  // modal functions
  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  // apply classes
  const alignImage = imageLeft ? 'align-left' : '';
  const renderVideo = hasVideo ? 'has-video' : '';

  return (
    <Section className={`two-cols ${alignImage} ${renderVideo}`} topPadding={topPadding} bottomPadding={bottomPadding}>
      <div className="wrapper">
        <OnVisible className="two-cols-text-container">
          {/* TAGLINE */}
          <div className="two-cols-tagline-container">
            {hasTaglineIcon && <Image className="two-cols-tagline-icon" image={taglineIcon} objectFit="contain" />}
            {taglineHtml && <div className="two-cols-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
          </div>
          {/* TITLE, CONTENT, CTA */}
          {titleHtml && <div className="two-cols-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {contentHtml && <div className="two-cols-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
          {(hasCta || addVideoButton) && (
            <div className="buttonsContainer">
              {hasCta && (
                <Button className="two-cols-cta" to={ctaLink.url}>
                  {ctaText.text}
                </Button>
              )}
              {addVideoButton && (
                <Button to={video?.url} onClick={(event) => openModal(event)}>
                  Play Video
                </Button>
              )}
            </div>
          )}
        </OnVisible>
        {/* IMAGE */}
        <OnVisible className="two-cols-img-vid-container">
          {hasVideoContent && !hasImage && (
            <>
              <div role="img" onClick={openModal} className="two-cols-video-container">
                <Video
                  key="Particular Audience"
                  id="particular-audience"
                  placeholder={placeholder?.gatsbyImageData?.base64}
                  src={video?.url}
                  objectFit={objectFit}
                />
                <a href="#openmodal" onClick={openModal}>
                  {/* <Image className="play-button" image={{ url: playIcon }} /> */}
                </a>
              </div>
              {youTubeUrl && (
                <Modal active={showModal} closeModal={closeModal} className="iframe-modal">
                  <div className="iframe-container">
                    <ReactPlayer
                      url={youTubeUrl}
                      volume={1}
                      muted={false}
                      config={{
                        youtube: {
                          playerVars: { autoplay: 1, controls: 1 },
                        },
                      }}
                    />
                  </div>
                </Modal>
              )}
            </>
          )}
          {hasImage && !hasVideo && <Image imgStyle={{ objectFit }} className="two-cols-image" image={image} />}
        </OnVisible>
      </div>
    </Section>
  );
};

export default TwoCols;
