import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Image, Link } from 'components';
import slugify from 'slugify';
import chevronCircle from '../../images/chevron_circle_primary.svg';
import './styles.scss';

const CategoryGrid = () => {
  const data = useStaticQuery(graphql`
    query CategoryList {
      allPrismicCategory(
        filter: { uid: { ne: "featured" } }
        sort: { order: ASC, fields: data___category_order_number }
      ) {
        nodes {
          uid
          data {
            category_name {
              text
              # html
            }
          }
        }
      }
    }
  `);

  const [categoriesOpen, setCategoriesOpen] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setCategoriesOpen(!categoriesOpen);
  };

  const openCategories = categoriesOpen ? 'reveal-categories' : '';

  const { allPrismicCategory } = data;
  const { nodes: categories } = allPrismicCategory;

  return (
    <section className="category-grid">
      <div className="wrapper">
        <span className="category-grid-title">Categories</span>
        <div className={`category-grid-container ${openCategories}`}>
          {categories.map((category) => {
            const { data: categoryData, uid: categorySlug } = category;
            const { category_name: categoryName } = categoryData;
            // const categorySlug = categoryName.text.replace(/\s+/g, '-').toLowerCase();
            const cardLink = `/${categorySlug}`;

            return (
              <Link key={categorySlug} to={cardLink} className="category-grid-cell-link">
                <div className="category-grid-cell">
                  <span className="category-grid-cell-text">{categoryName.text}</span>
                  <Image image={{ url: chevronCircle }} />
                </div>
              </Link>
            );
          })}
        </div>
        {categories.length > 4 && (
          <a className="category-grid-view-all" onClick={handleClick} href="#view-all">
            {categoriesOpen ? 'View less' : `View all (+${categories.length - 4})`}
          </a>
        )}
      </div>
    </section>
  );
};

export default CategoryGrid;
