import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Button, Section } from 'components';

import './styles.scss';

const HighlightColumns = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, top_padding: topPadding, bottom_padding: bottomPadding } = primary;

  // content checks
  const titleHtml = title?.html;

  return (
    <Section className="highlight-columns" topPadding={topPadding} bottomPadding={bottomPadding}>
      <div className="wrapper">
        {titleHtml && <div className="highlight-columns-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        <div className="highlight-columns-container">
          {items.map((item, index) => {
            const {
              cta_link: ctaLink,
              cta_text: ctaText,
              feature_content: content,
              feature_icon: icon,
              feature_title: featureTitle,
            } = item;

            // content checks
            const hasCta = ctaLink && ctaText.text;
            const contentHtml = content?.html;
            const featureTitleHtml = featureTitle?.html;

            return (
              <OnVisible className="highlight-columns-column" style={{ transitionDelay: `${index * 0.3}s` }}>
                {icon && <Image className="highlight-column-image" image={icon} objectFit="contain" />}
                {featureTitleHtml && (
                  <div className="highlight-column-title" dangerouslySetInnerHTML={{ __html: featureTitleHtml }} />
                )}
                {contentHtml && (
                  <div className="highlight-column-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
                )}
                {hasCta && <Button to={ctaLink.url}>{ctaText.text}</Button>}
              </OnVisible>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default HighlightColumns;
