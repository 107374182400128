import React from 'react';
import { RichText } from 'components';

import './styles.scss';

const Label = ({ labelHtml, className, theme = 'default' }) => {
  if (labelHtml === '<p></p>') {
    return null;
  }
  return <RichText html={labelHtml} className={`${className} labelComponent ${theme}`} p1 />;
};

export default Label;
