import React, { useState } from 'react';
import { Section, Modal, Image, Label, RichText, Button } from 'components';
import ReactPlayer from 'react-player';

import './styles.scss';

function isYouTubeUrl(inputString) {
  if (!inputString) return false;
  const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;

  return youtubePattern.test(inputString);
}

const CaseStudy = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    display_text_on_right: isRightAligned,
    background_color: backgroundColor,
    image,
    content,
    label,
    title,
    logo,
    primary_button_link: primaryButtonLink,
    primary_button_text: primaryButtonText,
    secondary_button_link: secondaryButtonLink,
    secondary_button_text: secondaryButtonText,
    quote,
    quote_author: quoteAuthor,
    author_job_title: authorJobTitle,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
    label_color: labelColor,
  } = primary;

  // content checks
  const labelHtml = label?.html;
  const logoUrl = logo?.url;
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const quoteHtml = quote?.html;
  const quoteAuthorText = quoteAuthor?.text;
  const authorJobTitleText = authorJobTitle?.text;
  const imageUrl = image?.url;
  const isSecondaryLinkYouTube = isYouTubeUrl(secondaryButtonLink?.url);

  const [showModal, setShowModal] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  return (
    <Section
      className="caseStudy"
      containerClassName={`wrapper ${isRightAligned && 'rightAligned'}`}
      sliceName="caseStudy"
      backgroundColor={backgroundColor}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      <div className="textContainer">
        <Label labelHtml={labelHtml} className="label desktop" theme={labelColor} />
        {logoUrl && <Image image={{ url: logoUrl }} className="logoImage desktop" />}
        <RichText html={titleHtml} className="title" h4 />
        {contentHtml && <RichText html={contentHtml} className="content" p2 />}
        {quoteHtml && <RichText html={quoteHtml} className="quote" p2 />}
        {quoteAuthorText && (
          <p className="author">
            - {quoteAuthorText} - {authorJobTitleText}
          </p>
        )}
        <CaseStudyButtons
          plink={primaryButtonLink?.url}
          ptext={primaryButtonText?.text}
          slink={secondaryButtonLink?.url}
          stext={secondaryButtonText?.text}
          openModal={openModal}
        />
      </div>

      <div className="infoContainer">
        <Label labelHtml={labelHtml} className="label mobile" theme={labelColor} />
        {logoUrl && <Image image={{ url: logoUrl }} className="logoImage mobile" />}
        {imageUrl && <Image image={{ url: imageUrl }} className="infoImage" />}

        <div className="metricsContainer">
          {items.map((item, index) => {
            const { metric, metric_label: metricLabel } = item;
            const hasValues = metric?.text && metricLabel?.text;
            const itemKey = `${item.metric?.text}-${index}`;

            return (
              <div key={itemKey}>
                {hasValues && (
                  <div className="metricItem">
                    <div className="metric">{metric?.text}</div>
                    <div className="metricLabel">{metricLabel?.text}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {isSecondaryLinkYouTube && (
        <Modal active={showModal} closeModal={closeModal} className="iframe-modal">
          <div className="iframe-container">
            <ReactPlayer
              url={secondaryButtonLink?.url}
              volume={1}
              muted={false}
              width="100%"
              height="100%"
              config={{
                youtube: {
                  playerVars: { autoplay: 1, controls: 1 },
                },
              }}
            />
          </div>
        </Modal>
      )}
    </Section>
  );
};

const CaseStudyButtons = (props) => {
  const { plink, ptext, slink, stext, openModal } = props;

  return (
    <div className="CaseStudyButtons">
      {plink && ptext && (
        <Button theme="primary" removeArrows className="primaryButton" to={plink}>
          {ptext}
        </Button>
      )}
      {slink && stext && !isYouTubeUrl(slink) && (
        <Button className="secondaryButton" to={slink}>
          {stext}
        </Button>
      )}
      {slink && stext && isYouTubeUrl(slink) && (
        <Button className="secondaryButton" onClick={openModal}>
          {stext}
        </Button>
      )}
    </div>
  );
};

export default CaseStudy;
