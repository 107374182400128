import React from 'react';
import { PostGrid, LatestPosts } from 'components';
import { useStaticQuery, graphql } from 'gatsby';

import './styles.scss';

function toSentenceCase(inputString) {
  if (!inputString) return '';

  const firstChar = inputString.charAt(0).toUpperCase();
  const restOfString = inputString.slice(1).toLowerCase();

  return firstChar + restOfString;
}

const BlogPostGrid = (props) => {
  const { data } = props;
  const { primary } = data;
  const { category, title, stacked, top_padding: topPadding, bottom_padding: bottomPadding } = primary;
  const { uid } = category;

  const blogPostsData = useStaticQuery(graphql`
    query BlogPostList {
      allPrismicBlogPost {
        nodes {
          uid
          data {
            author {
              text
              html
            }
            category {
              id
              uid
            }
            excerpt {
              html
              text
            }
            hero_image {
              url
            }
            title {
              html
              text
            }
          }
        }
      }
    }
  `);

  const filterAndLimitPostsByCategory = (posts, categoryUid) => {
    if (categoryUid === undefined) {
      // if you just want latest then leave category blank
      return posts.slice(0, 3);
    }
    const filteredPosts = posts.filter((post) => post?.data?.category?.uid === categoryUid);
    return filteredPosts.slice(0, 3);
  };

  const { allPrismicBlogPost } = blogPostsData;
  const { nodes: blogPosts } = allPrismicBlogPost;
  const filteredBlogPosts = filterAndLimitPostsByCategory(blogPosts, uid?.toLowerCase());

  if (filteredBlogPosts && filteredBlogPosts?.length < 1) return null;

  if (stacked) {
    return (
      <LatestPosts
        posts={filteredBlogPosts}
        title={title?.text}
        topPadding={topPadding}
        bottomPadding={bottomPadding}
      />
    );
  }

  return (
    <PostGrid
      posts={filteredBlogPosts}
      title={title?.text || toSentenceCase(uid)}
      categoryUid={uid}
      stacked={stacked}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    />
  );
};

export default BlogPostGrid;
