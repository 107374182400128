import React, { useEffect } from 'react';
import { Footer, Header, SEO } from 'components';
import 'typeface-inter';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, customSEO, seo, location } = props;

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
      document.documentElement.style.setProperty('--vw', `${windowWidth}px`);
      document.documentElement.style.setProperty('--vh', `${windowHeight}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // console.log('SEO', seo);
  return (
    <>
      <Header />
      {!customSEO && <SEO {...seo} location={location} />}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
