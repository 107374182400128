import React from 'react';
import { Section, Button, Image, RichText } from 'components';

import './styles.scss';

const InfoCardGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, top_padding: topPadding, bottom_padding: bottomPadding } = primary;

  return (
    <Section
      sliceName="InfoCardGrid"
      containerClassName="wrapper"
      className="section InfoCardGrid"
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      {title?.html && <RichText className="title" html={title?.html} h2 />}
      <div className="infoCardsContainer">
        {items.map((item, index) => {
          const { button_link: ctaLink, button_text: buttonTextData, content, icon, title: infoCardTitle } = item;
          let buttonText = buttonTextData.text;
          if (buttonText === '') buttonText = 'Learn More';
          const contentHtml = content?.html;
          const infoCardTitleHtml = infoCardTitle?.html;

          return (
            <div className="infoCard" style={{ transitionDelay: `${index * 0.3}s` }} key={infoCardTitle.text}>
              {icon?.url && <Image className="icon" image={icon} objectFit="contain" />}
              {infoCardTitleHtml && <RichText className="infoCardTitle" html={infoCardTitleHtml} h5 />}
              {contentHtml && <RichText className="content" html={contentHtml} />}
              {ctaLink?.url && <Button to={ctaLink?.url}>{buttonText}</Button>}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default InfoCardGrid;
