import React from 'react';
import OnVisible from 'react-on-visible';
import { Link, BackgroundLogo, Section } from 'components';
import './styles.scss';

const TextBlock = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    background_colour: backgroundColor,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    show_logo_background: showBg,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
  } = primary;

  const contentHtml = content?.html;
  const titleHtml = title?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  return (
    <Section
      className="text-block"
      backgroundColor={backgroundColor}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      {showBg && <BackgroundLogo />}
      <div className="wrapper">
        {titleHtml && <div className="text-block-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <div className="text-block-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        {hasCta && (
          <OnVisible>
            <Link className="button filled" to={ctaLink.url}>
              {ctaText.text}
            </Link>
          </OnVisible>
        )}
      </div>
    </Section>
  );
};

export default TextBlock;
