import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

import './styles.scss';

const Accordion = (props) => {
  const { className, itemClassName, items } = props;

  const [current, setCurrent] = useState();

  const itemsWithAddedKeys = items?.map((item, i) => {
    item.key = `faqItem-${i}`;
    return item;
  });

  return (
    <div className={`${className || ''}`}>
      {itemsWithAddedKeys.map((item) => (
        <AccordionItem
          key={item?.key}
          faq={item}
          className={itemClassName || ''}
          current={current}
          setCurrent={setCurrent}
        />
      ))}
    </div>
  );
};

export default Accordion;
