import React, { useState, useEffect, useRef } from 'react';
import { Image } from 'components';
import ReactDOM from 'react-dom';
import Slider from 'react-slick';
import left from '../../images/blackChevronLeft.svg';
import right from '../../images/blackChevronRight.svg';

import './styles.scss';

const Arrow = (props) => {
  const { style, onClick, portalRef, isNext } = props;
  if (!portalRef) return null;
  return ReactDOM.createPortal(
    <button type="button" style={style} className="carouselButtons" onClick={onClick} aria-label="Change Slide">
      {isNext ? (
        <Image className="rightArrowImage" image={{ url: right }} alt="Arrow Next" />
      ) : (
        <Image className="leftArrowImage" image={{ url: left }} alt="Arrow Prev" />
      )}
    </button>,
    portalRef
  );
};

const Dots = ({ dots, portalRef }) => {
  if (!portalRef) return null;
  return ReactDOM.createPortal(<ul className="slick-dots">{dots}</ul>, portalRef);
};

const Carousel = (props) => {
  const { children, containerClassName, sliderRef, customDotsRef, customLeftArrowRef, customRightArrowRef, settings } =
    props;

  const [leftArrow, setLeftArrow] = useState(null);
  const [rightArrow, setRightArrow] = useState(null);
  const [dots, setDots] = useState(null);

  const settingsObj = {
    prevArrow: <Arrow portalRef={leftArrow} />,
    nextArrow: <Arrow portalRef={rightArrow} isNext />,
    appendDots: (el) => <Dots dots={el} portalRef={dots} />,
    ...settings,
  };

  // Pass sliderRef to parent component
  const sliderElement = useRef(null);
  const controlsRef = useRef(null);
  const dotsRef = useRef(null);

  useEffect(() => {
    if (customLeftArrowRef) {
      setLeftArrow(customLeftArrowRef?.current);
    }
    if (!customLeftArrowRef && controlsRef) {
      setLeftArrow(controlsRef?.current);
    }

    if (customRightArrowRef) {
      setRightArrow(customRightArrowRef?.current);
    }
    if (!customRightArrowRef && controlsRef) {
      setRightArrow(controlsRef?.current);
    }

    if (customDotsRef) {
      setDots(customDotsRef?.current);
    }
    if (!customDotsRef && dotsRef) {
      setDots(dotsRef?.current);
    }
  }, [customRightArrowRef, customLeftArrowRef, customDotsRef, controlsRef, dotsRef]);

  useEffect(() => {
    if (sliderRef) {
      sliderRef(sliderElement);
    }
  }, [sliderRef]);

  return (
    <div className={`carousel ${containerClassName || ''}`}>
      <Slider {...settingsObj} ref={sliderElement}>
        {children}
      </Slider>
      {settings?.dots && !customDotsRef && <div className="carousel-dots" ref={dotsRef} />}
      {settings?.arrow && !customLeftArrowRef && !customRightArrowRef && (
        <div className="carousel-controls-container">
          <div className="carousel-controls" ref={controlsRef} />
        </div>
      )}
    </div>
  );
};

export default Carousel;
