import React from 'react';
import { Link, Label, RichText, Image, Button } from 'components';

const MegaNavDropdown = ({ feature, dropdownItems, customClassName }) => {
  const AreColLinksEmpty = (columnItem) => {
    if (columnItem && columnItem.links && columnItem.links.length > 0) {
      return !columnItem.links.some((link) => link.link_text.html !== '');
    }
    return true;
  };

  const countPopulatedColumnItems = (columnItems) => {
    return columnItems.reduce((count, columnItem) => {
      if (!AreColLinksEmpty(columnItem)) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const checkIfNoLabels = (columnsArray) => {
    return columnsArray.every((column) => column.label.html === '' || column.label.html === '<p></p>');
  };

  const numberOfColumns = countPopulatedColumnItems(dropdownItems);
  const hasFeature = feature?.image?.url && feature?.title?.html && feature?.content?.html;

  const shouldDisplaySingleCol = numberOfColumns === 1 && !hasFeature;

  return (
    <div className={`megaNavSublinks ${!hasFeature && 'noFeature'} ${shouldDisplaySingleCol && 'singleCol'}`}>
      <div className="megaNavDropdownContainer">
        <div className={`columnsContainer ${!hasFeature && 'noFeature'}`}>
          {dropdownItems.map((columnItem) => {
            const label = columnItem?.label;
            const hasLabel = label?.text;
            const labelColor = columnItem?.labelColor;
            if (AreColLinksEmpty(columnItem)) return null;

            return (
              <div className={`${customClassName}-column`} key={Math.random() * 1000}>
                {columnItem?.links.map((item, i) => {
                  const { link: url, link_description: description, link_text: title, icon } = item;
                  const linkKey = `${title?.text}-${i}`;

                  return (
                    <div
                      className={`${customClassName}-sublink ${!hasLabel && i === 0 && 'noLabel'} ${
                        checkIfNoLabels(dropdownItems) && 'removePadding'
                      }`}
                      key={Math.random() * 1000}
                      style={{ transitionDelay: `${0.2}s` }}
                    >
                      {hasLabel && i === 0 && <Label labelHtml={label?.html} theme={labelColor} className="label" />}
                      <Link
                        key={linkKey}
                        to={url?.url}
                        className={`${customClassName}-sublink-link ${icon?.url && 'withIcon'}`}
                      >
                        {icon?.url && <Image image={{ url: icon?.url }} className="icon" />}
                        <div className="textContainer">
                          <RichText
                            as="span"
                            html={title?.html}
                            h5
                            className={`${customClassName}-sublink-text title`}
                          />
                          {description?.html !== '<p></p>' && (
                            <RichText
                              as="span"
                              html={description?.html}
                              p1
                              className={`${customClassName}-sublink-subtext description`}
                            />
                          )}
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {hasFeature && (
          <div className="featureContainer">
            <div className="feature">
              {feature?.label?.text && <Label labelHtml={feature?.label?.html} className="label" theme="white" />}
              <div className="imageContainer">
                <Image image={{ url: feature?.image?.url }} />
              </div>
              <RichText html={feature?.title?.html} h5 className="featureTitle" />
              <RichText html={feature?.content?.html} p1 className="featureContent" />
              {feature?.link && (
                <Button className="ctaButton" to={feature?.link?.url}>
                  {feature?.linkText?.text || 'Learn More'}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MegaNavDropdown;
