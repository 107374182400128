/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, Image, Button } from 'components';
import MegaNavDropdown from './megaNavDropdown';
import chevron from '../../images/black_chevron.svg';

import './styles.scss';

const MegaNav = (props) => {
  const { classNamePrefix = 'main', onMobileNavItemClick, activeMobileNavItem } = props;
  const className = classNamePrefix ? `${classNamePrefix}-navigation` : 'navigation';
  const [activeSubNavItem, setActiveSubNavItem] = useState('none');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      handleResize();
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const showNavSublinks = (event, itemText) => {
    if (!isSmallScreen) return;

    if (activeSubNavItem === itemText) {
      setActiveSubNavItem('none');
    } else {
      setActiveSubNavItem(itemText);
      onMobileNavItemClick(itemText); // send to parent (Header)
    }
  };

  const onNavItemClick = (event, itemText, navChild) => {
    if (navChild && isSmallScreen) {
      showNavSublinks(event, itemText);
    }
  };

  useEffect(() => {
    setActiveSubNavItem(activeMobileNavItem);
  }, [activeMobileNavItem]);

  const data = useStaticQuery(graphql`
    query data {
      allPrismicSettings {
        nodes {
          data {
            primary_button_link {
              url
            }
            primary_button_text {
              text
              html
            }
            secondary_button_link {
              url
            }
            secondary_button_text {
              text
              html
            }
            navigation {
              dropdown {
                document {
                  ... on PrismicDropDown {
                    id
                    data {
                      label_one_color
                      column_one_label {
                        html
                        text
                      }
                      column_one_links {
                        icon {
                          url
                        }
                        link {
                          url
                        }
                        link_description {
                          text
                          html
                        }
                        link_text {
                          text
                          html
                        }
                      }

                      label_two_color
                      column_two_label {
                        html
                        text
                      }
                      column_two_links {
                        icon {
                          url
                        }
                        link {
                          url
                        }
                        link_description {
                          text
                          html
                        }
                        link_text {
                          text
                          html
                        }
                      }

                      label_three_color
                      column_three_label {
                        html
                        text
                      }
                      column_three_links {
                        icon {
                          url
                        }
                        link {
                          url
                        }
                        link_description {
                          text
                          html
                        }
                        link_text {
                          text
                          html
                        }
                      }

                      label_four_color
                      column_four_label {
                        html
                        text
                      }
                      column_four_links {
                        icon {
                          url
                        }
                        link {
                          url
                        }
                        link_description {
                          text
                          html
                        }
                        link_text {
                          text
                          html
                        }
                      }

                      feature_title {
                        text
                        html
                      }
                      feature_link_text {
                        html
                        text
                      }
                      feature_link {
                        url
                      }
                      feature_label {
                        html
                        text
                      }
                      feature_image {
                        url
                      }
                      feature_content {
                        text
                        html
                      }
                    }
                  }
                }
              }
              link {
                url
              }
              link_tem {
                text
                html
              }
            }
          }
        }
      }
    }
  `);

  const { allPrismicSettings } = data;
  const { nodes } = allPrismicSettings;
  const navItems = nodes[0]?.data?.navigation;
  const primaryButtonText = nodes[0]?.data?.primary_button_text?.text;
  const primaryButtonLink = nodes[0]?.data?.primary_button_link?.url;
  const secondaryButtonText = nodes[0]?.data?.secondary_button_text?.text;
  const secondaryButtonLink = nodes[0]?.data?.secondary_button_link?.url;

  return (
    <nav className={`${className} megaNav`}>
      {navItems &&
        navItems.map((navItem, idx) => {
          const { link_tem: linkItem, link, dropdown } = navItem;
          const navChild = dropdown?.document?.data;
          const key = `${navItem?.linkItem?.text}-${idx}`;
          const columnsArray = [
            {
              links: navChild?.column_one_links,
              label: navChild?.column_one_label,
              labelColor: navChild?.label_one_color,
            },
            {
              links: navChild?.column_two_links,
              label: navChild?.column_two_label,
              labelColor: navChild?.label_two_color,
            },
            {
              links: navChild?.column_three_links,
              label: navChild?.column_three_label,
              labelColor: navChild?.label_three_color,
            },
            {
              links: navChild?.column_four_links,
              label: navChild?.column_four_label,
              labelColor: navChild?.label_four_color,
            },
          ];
          const feature = {
            label: navChild?.feature_label,
            image: navChild?.feature_image,
            title: navChild?.feature_title,
            content: navChild?.feature_content,
            linkText: navChild?.feature_link_text,
            link: navChild?.feature_link,
          };

          return (
            <div key={key}>
              {activeSubNavItem === 'none' && (
                <div
                  key={key}
                  className={`${className}-link navItem ${
                    activeSubNavItem === linkItem?.text ? 'active' : 'notActive'
                  }`}
                  onClick={(event) => onNavItemClick(event, linkItem?.text, navChild)}
                >
                  {navChild ? (
                    <div className={`${className}-link-text navItemParent`}>
                      <Link
                        to={link?.url}
                        onClick={navChild ? (event) => showNavSublinks(event, linkItem?.text) : () => {}}
                      >
                        {linkItem?.text}
                      </Link>
                      <Button
                        theme="minimal"
                        removeArrows
                        className="header-nav-link-chevron"
                        onClick={(event) => showNavSublinks(event, linkItem?.text)}
                      >
                        <Image image={{ url: chevron }} alt="View More" />
                      </Button>
                    </div>
                  ) : (
                    <Link to={link?.url} className={`${className}-link-text navItemParent`}>
                      {linkItem?.text}
                    </Link>
                  )}
                  {navChild && (
                    <MegaNavDropdown feature={feature} customClassName={className} dropdownItems={columnsArray} />
                  )}
                </div>
              )}
              {activeSubNavItem === linkItem?.text && (
                <div className="mobileSubNav">
                  {navChild && (
                    <MegaNavDropdown feature={feature} customClassName={className} dropdownItems={columnsArray} />
                  )}
                </div>
              )}
            </div>
          );
        })}
      <div className="megaNavButtons">
        <Button removeArrows theme="minimal" className="loginButton" to={secondaryButtonLink}>
          {secondaryButtonText}
        </Button>
        <Button removeArrows theme="primary" to={primaryButtonLink}>
          {primaryButtonText}
        </Button>
      </div>
    </nav>
  );
};

export default MegaNav;
