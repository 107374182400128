import React from 'react';
import { Link } from 'components';
import { siteName } from '../../../config/website';
import './styles.scss';

const Logo = ({ className }) => (
  <Link className={`logo ${className}`} to="/">
    {siteName}
  </Link>
);

export default Logo;
