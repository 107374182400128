import React, { useState } from 'react';
import { Image, Video, Modal, Section } from 'components';
import OnVisible from 'react-on-visible';
import ReactPlayer from 'react-player';

import './styles.scss';

const TitleStats = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    title,
    tagline,
    tagline_icon: taglineIcon,
    video,
    video_image_placeholder: placeholder,
    youtube_link: youtubeLink,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;
  const hasTaglineIcon = taglineIcon?.url;
  const youtubeUrl = youtubeLink?.url;

  // state
  const [showModal, setShowModal] = useState(false);
  // class for video/two cols variant
  const hasVideo = video && video.url;

  // modal functions
  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  return (
    <Section className={`title-stats ${hasVideo && 'has-video'}`} topPadding={topPadding} bottomPadding={bottomPadding}>
      <div className="wrapper">
        {/* VIDEO */}
        {hasVideo && (
          <>
            <div role="img" onClick={() => setShowModal(true)} className="title-stats-video-container">
              <Video
                key="Particular Audience"
                id="particular-audience"
                placeholder={placeholder?.gatsbyImageData?.base64}
                src={hasVideo}
              />
              <a href="#openmodal" onClick={openModal}>
                {/* <Image className="play-button" image={{ url: playIcon }} /> */}
              </a>
            </div>
            <Modal active={showModal} closeModal={closeModal} className="iframe-modal">
              <div className="iframe-container">
                <ReactPlayer
                  url={`${youtubeUrl} || https://www.youtube.com/watch?v=2yoLpAJUL14&feature=youtu.be&ab_channel=JamesTaylor`}
                  volume={1}
                  muted={false}
                  config={{
                    youtube: {
                      playerVars: { autoplay: 1, controls: 1 },
                    },
                  }}
                />
              </div>
            </Modal>
          </>
        )}
        {/* TEXT */}
        <div className="title-stats-text-container">
          <div className="title-stats-tagline-title">
            {taglineHtml && taglineIcon && (
              <div className="title-stats-tagline-container">
                {hasTaglineIcon && (
                  <Image className="title-stats-tagline-icon" image={taglineIcon} objectFit="contain" />
                )}
                <div className="title-stats-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />
              </div>
            )}
            {titleHtml && <div className="title-stats-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          </div>
          <div className="title-stats-grid">
            {items.map((item, index) => {
              const { stat_title: statTitle, stat_value: statValue } = item;
              const hasTitleValue = statTitle?.text && statValue?.text;
              return (
                <OnVisible className="title-stats-grid-item" style={{ transitionDelay: `${index * 0.35}s` }}>
                  {hasTitleValue && (
                    <>
                      <div className="title-stats-item-value" dangerouslySetInnerHTML={{ __html: statValue.html }} />
                      <div className="title-stats-item-title" dangerouslySetInnerHTML={{ __html: statTitle.html }} />
                    </>
                  )}
                </OnVisible>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default TitleStats;
