import React from 'react';
import { Button, Section } from 'components';
import BlogPostCard from '../BlogPostCard/index.jsx';

import './styles.scss';

const PostGrid = (props) => {
  const { posts, className, categoryUid, title, showExcerpt, hasFeaturedPost, topPadding, bottomPadding } = props;
  if (!posts || posts.length < 1) return null;

  const vericalLayout = title === 'Latest Posts' ? 'latest-posts' : '';
  const category = categoryUid || title?.toLowerCase()?.replace(/ /g, '-') || null;
  const defaultLinkResolve = `/${category}`;

  const hideLink = category === 'featured-posts' || category === 'recent-posts' || category === 'latest-posts';

  const useH3 = category === 'recent-posts' || category === 'latest-posts';

  const HeadingElement = useH3 ? 'h3' : 'h2';

  return (
    <Section
      className={`post-grid ${className || ''}`}
      key={title}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      <div className="wrapper">
        {title && (
          <div className="post-grid-header-container">
            <HeadingElement className="post-grid-title">{title}</HeadingElement>
            {!hideLink && (
              <Button to={defaultLinkResolve}>
                <span className="post-grid-view-all">View all posts</span>
              </Button>
            )}
          </div>
        )}
        <div className={`post-grid-container ${className || vericalLayout || ''}`}>
          {posts.length > 0 &&
            posts.map((post, index) => (
              <BlogPostCard
                key={post?.uid}
                data={post?.data}
                uid={post?.uid}
                className={className}
                index={index}
                showExcerpt={showExcerpt || (hasFeaturedPost && index === 0)}
              />
            ))}
        </div>
      </div>
    </Section>
  );
};

export default PostGrid;
