import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import YouTube from 'react-youtube';
import './styles.scss';

const YoutubeVideo = props => {
  const { data, isBlog } = props;
  const { primary } = data;
  const { video_id: videoId } = primary;

  const [visible, setVisible] = useState(false);

  const hasVideo = videoId && videoId.text;

  if (!hasVideo) return null;

  return (
    <OnVisible
      className={`youtube-video ${isBlog ? 'is-blog' : ''}`}
      onChange={isVisible => setVisible(isVisible)}
      percent={-200}
    >
      <div className="youtube-video-embed">
        {visible && (
          <YouTube
            videoId={videoId.text}
            opts={{
              height: '410',
              width: '729',
              playerVars: {
                autoplay: false,
                allowfullscreen: true,
                rel: 0,
                modestbranding: 1,
                loop: 1,
                showinfo: 0,
              },
            }}
          />
        )}
      </div>
    </OnVisible>
  );
};

export default YoutubeVideo;
