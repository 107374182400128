import React from 'react';
import OnVisible from 'react-on-visible';
import { ContactForm } from 'components';
import './styles.scss';

const Form = props => {
  const { data, location } = props;
  const prefilledEmail = location?.state?.email;
  const { primary } = data;
  const { content, form_type: type, title } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  return (
    <OnVisible className="form-slice" id="form">
      <div className="wrapper">
        <div className="form-slice-header">
          {titleHtml && <div className="form-slice-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {contentHtml && <div className="form-slice-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        </div>
        {type === 'Contact' ? (
          <ContactForm title={titleHtml} content={contentHtml} prefilledEmail={prefilledEmail} />
        ) : (
          <p>no form</p>
        )}
      </div>
    </OnVisible>
  );
};

export default Form;
