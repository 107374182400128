module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Particular Audience', // Default Site Title used for SEO & PWA
  description: 'A different website for every customer', // Default Site Decription used for SEO
  siteName: 'Particular Audience', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/open-graph.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/images/maskable-icon.png', // Used for manifest favicon generation
  shortName: 'PA', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Particular Audience', // Author for schemaORGJSONLD
  themeColor: '#FFFFFF',
  backgroundColor: '#6AFDEF',
  socialLinks: [
    'https://www.linkedin.com/company/particularaudience/',
    'https://www.facebook.com/particularaudience/',
    'https://twitter.com/particularaud',
  ],
  twitter: 'ParticularAud', // Twitter Username
  googleTagManagerId: 'GTM-T545J2T',
};
