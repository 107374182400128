import React from 'react';
import { Button, RichText, Label, Section, Link } from 'components';

import './styles.scss';

function checkDivisibility(number) {
  if (number % 4 === 0) {
    return 'fourCol';
  }
  if (number % 3 === 0) {
    return 'threeCol';
  }
  return 'twoCol';
}

const Metrics = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    button_link: buttonLink,
    button_text: buttonText,
    background_color: backgroundColor,
    label,
    title,
    label_color: labelColor,
  } = primary;

  return (
    <Section className="metricsSlice" sliceName="metricsSlice" backgroundColor={backgroundColor}>
      <div className="wrapper">
        <div className="textContainer">
          {label?.text && <Label labelHtml={label?.html} className="label" theme={labelColor} />}
          <RichText html={title?.html} className="title" h2 />
        </div>
        <div className={`metricsContainer ${checkDivisibility(items?.length)}`}>
          {items.map((item, index) => {
            const { metric_title: metricTitle, metric, metric_link: link } = item;
            const hasValues = metricTitle?.text && metric?.text;
            const itemKey = `${item.metric_title.text}-${index}`;

            if (!hasValues) return null;

            return (
              <Link to={link?.url} className="metricsItem" key={itemKey}>
                <span className="metric">{metric?.text}</span>
                <span className="title">{metricTitle?.text}</span>
              </Link>
            );
          })}
        </div>
        {buttonLink?.url && buttonText?.text && (
          <Button className="button" theme="primary" removeArrows to={buttonLink?.url}>
            {buttonText?.text}
          </Button>
        )}
      </div>
    </Section>
  );
};

export default Metrics;
