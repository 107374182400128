import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Link, Image, RichText } from 'components';
import chevronCircle from '../../images/chevron_circle_primary.svg';

import './styles.scss';

const CategoryGrid = (props) => {
  const categoriesData = useStaticQuery(graphql`
    query CategoryList {
      allPrismicCategory(
        filter: { uid: { ne: "featured" } }
        sort: { order: ASC, fields: data___category_order_number }
      ) {
        nodes {
          uid
          data {
            category_name {
              text
              # html
            }
          }
        }
      }
    }
  `);
  const { allPrismicCategory } = categoriesData;
  const { nodes: existingCategories } = allPrismicCategory;

  const { data } = props;
  const { primary, items } = data;
  const {
    use_custom_content: useCustomContent,
    title,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
  } = primary;

  const [categoriesOpen, setCategoriesOpen] = useState(false);

  let categoriesToUse = existingCategories;
  if (useCustomContent) categoriesToUse = items;

  const handleClick = (event) => {
    event.preventDefault();
    setCategoriesOpen(!categoriesOpen);
  };

  const openCategories = categoriesOpen ? 'reveal-categories' : '';
  return (
    <Section
      sliceName="CategoryGrid"
      containerClassName="wrapper"
      className="section CategoryGrid"
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      {title?.html && <RichText className="title" html={title?.html} h2 />}{' '}
      <div className={`gridContainer ${openCategories}`}>
        {categoriesToUse.map((category, i) => {
          let categoryName = category?.data?.category_name?.text;
          let categoryLink = `/${category?.uid}`;
          let categoryKey = `${categoryName}-${i}`;

          if (useCustomContent) {
            categoryName = category?.category_title?.text; // change to html
            categoryLink = category?.category_link?.url;
            categoryKey = `${categoryName}-${i}`;
          }

          return (
            <Link key={categoryKey} to={categoryLink} className="link">
              <div className="textAndIcon">
                <span className="text">{categoryName}</span>
                <Image image={{ url: chevronCircle }} />
              </div>
            </Link>
          );
        })}
      </div>
      {categoriesToUse.length > 4 && (
        <a className="viewAll" onClick={handleClick} href="#view-all">
          {categoriesOpen ? 'View less' : `View all (+${categoriesToUse.length - 4})`}
        </a>
      )}
    </Section>
  );
};

export default CategoryGrid;
