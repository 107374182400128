import React from 'react';
import { Accordion, Section, RichText, Label } from 'components';

import './styles.scss';

const Faq = (props) => {
  const { data } = props;
  const { primary, items } = data;

  const { background_color: backgroundColor, content, label, title, label_color: labelColor } = primary;

  const labelHtml = label?.html;
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  const escapeDoubleQuotes = (str) => str.replace(/\\([\s\S])|(")/g, '\\$1$2');

  const schema = `
  <script type="application/ld+json">
    {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      ${items.map(
        (item) => `
        {
          "@type": "Question",
          "name": "${item.faq_question?.text}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${escapeDoubleQuotes(item.faq_answer?.html)}"
          }
        }`
      )}]
    }
    </script>
  `;

  return (
    <>
      <Section className="faqSlice" sliceName="faqSlice" backgroundColor={backgroundColor}>
        <div className="wrapper">
          <div className="textContainer">
            <Label labelHtml={labelHtml} className="label" theme={labelColor} />
            <RichText html={titleHtml} className="title" h4 />
            <RichText html={contentHtml} className="content" h5 />
          </div>
          <Accordion items={items} className="accordionContainer" titleKey="faq_question" contentKey="faq_answer" />
        </div>
      </Section>
      <div dangerouslySetInnerHTML={{ __html: schema }} />
    </>
  );
};

export default Faq;
