import React, { useState, useEffect, useRef } from 'react';
import { RichText } from 'components';

import './styles.scss';

const AccordionItem = ({ faq, current, setCurrent, className }) => {
  const [active, setActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const calculateContentHeight = () => {
      if (ref.current) {
        const { scrollHeight } = ref.current;
        ref.current.style.setProperty('--content-height', `${scrollHeight}px`);
      }
    };
    calculateContentHeight();
    window.addEventListener('resize', calculateContentHeight);
    return () => window.removeEventListener('resize', calculateContentHeight);
  }, []);

  // closes faq item if not currently selected
  useEffect(() => {
    if (current !== faq?._key) setActive(false);
  }, [current]);

  const toggle = () => {
    if (current !== faq?._key) {
      setActive(true);
      setCurrent(current);
    } else if (current === faq?._key) {
      setActive(false);
      setCurrent('');
    }
    setActive(!active);
    setCurrent(faq?._key);
  };

  return (
    <div className={`${className} accordionItem ${active ? 'active' : ''}`}>
      <a className="titleContainer" href="#toggle" onClick={(event) => toggle(event)}>
        <RichText className="title" html={faq?.faq_question?.html} richTextRef={ref} h5 />
        <button className={`plusMinus ${active ? 'active' : ''}`} type="button">
          Toggle
        </button>
      </a>
      <div className="infoContainer" ref={ref}>
        <RichText className="content" html={faq?.faq_answer?.html} />
      </div>
    </div>
  );
};

export default AccordionItem;
