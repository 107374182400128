import React from 'react';
import { Image } from 'components';
import './styles.scss';

const OneColImage = (props) => {
  const { data } = props;
  const { primary } = data;
  const { image } = primary;
  return (
    <section className="one-col-image">
      <div className="wrapper">
        <Image image={image} />
      </div>
    </section>
  );
};

export default OneColImage;
