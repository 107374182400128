import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Slice } from 'components';

const Page = ({ location, data }) => {
  const { page } = data;
  const { data: pageData, first_publication_date: publishedDate, last_publication_date: updatedDate } = page;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
    publishedDate,
    updatedDate,
  };

  return (
    <Layout location={location} seo={seo}>
      {sliceData.map((slice) => (
        <Slice key={slice.id} data={slice} location={location} />
      ))}
    </Layout>
  );
};

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...TwoColsPageFragment
          ...TitleStatsPageFragment
          ...OneColImagePageFragment
          ...HighlightColumnsPageFragment
          ...FeatureCarouselPageFragment
          ...SearchPageFragment
          ...TestimonialPageFragment
          ...VideoPageFragment
          ...FeatureGridPageFragment
          ...TitleFeaturesPageFragment
          ...TextBlockPageFragment
          ...JobsBoardPageFragment
          ...FormPageFragment
          ...WysiwygPageFragment
          ...CardGridPageFragment
          ...HeaderFragment
          ...MetricsFragment
          ...FaqFragment
          ...BlogPostCarouselFragment
          ...GridFragment
          ...LogosPageFragment
          ...CaseStudyPageFragment
          ...BlogPostGridFragment
          ...CategoryGridFragment
          ...InfoCardGridFragment
        }
      }
    }
  }
`;
