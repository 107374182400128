import React from 'react';
import PostGrid from '../PostGrid/index.jsx';
import './styles.scss';

const LatestPosts = (props) => {
  const { posts, title, topPadding, bottomPadding } = props;

  return (
    <section className="latest-posts">
      <PostGrid
        posts={posts}
        title={title}
        showExcerpt
        className="latest-posts"
        topPadding={topPadding}
        bottomPadding={bottomPadding}
      />
    </section>
  );
};

export default LatestPosts;
