import React from 'react';
import Sticky from 'react-sticky-el';
import { Image } from 'components';
import BlogPostCard from '../BlogPostCard/index.jsx';
import facebookIcon from '../../images/facebook.svg';
import twitterIcon from '../../images/twitter.svg';
import linkedinIcon from '../../images/linkedin.svg';
import './styles.scss';

const BlogPostSideBar = props => {
  const { filteredPosts, location, title } = props;
  const { pathname } = location;
  const boundary = '.blog-post-content-container';
  const rootUrl = `https://particularaudience.com${pathname}`;

  return (
    <Sticky
      stickyClassName="sticky"
      boundaryElement={boundary}
      hideOnBoundaryHit={false}
      className="blog-post-side-bar"
    >
      <div className="blog-post-side-bar-socials">
        <span>Share</span>
        <div className="blog-post-side-bar-social-icons">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${rootUrl}&text=${title}`}
            title="Facebook"
            target="__blank"
          >
            <Image image={{ url: facebookIcon }} />
          </a>
          <a href={`https://twitter.com/intent/tweet?url=${rootUrl} `} title="Twitter" target="__blank">
            <Image image={{ url: twitterIcon }} />
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${rootUrl}&title=${title?.text}&summary=&source=`}
            title="LinkedIn"
            target="__blank"
          >
            <Image image={{ url: linkedinIcon }} />
          </a>
        </div>
      </div>
      <div className="blog-post-side-bar-card-list">
        {filteredPosts.length > 0 && <span className="blog-post-side-bar-title">Related articles</span>}
        {filteredPosts.length > 0 &&
          filteredPosts.map(filteredPost => (
            <BlogPostCard
              key={filteredPost.uid}
              data={filteredPost.data}
              uid={filteredPost.uid}
              className="related-posts"
            />
          ))}
      </div>
    </Sticky>
  );
};

export default BlogPostSideBar;
