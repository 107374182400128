import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import './styles.scss';

const Image = (props) => {
  const { image, alt, className = '', imgStyle, ...otherProps } = props;

  const Placeholder = () => <div className={`gatsby-image placeholder ${className}`} />;

  if (!image) return <Placeholder />;

  if (image.localFile && !image.gatsbyImageData) {
    console.error(
      'Using localFile for Gatsby Image - update query to use gatsbyImageData directly on the image object'
    );
    return <Placeholder />;
  }

  if (image.gatsbyImageData) {
    return (
      <GatsbyImage
        className={`gatsby-image ${className}`}
        image={image.gatsbyImageData}
        alt={image.alt || alt || ''}
        imgStyle={imgStyle}
        {...otherProps}
      />
    );
  }

  if (image.url) {
    return (
      <div className={`gatsby-image ${className}`}>
        <img src={image.url} alt={image.alt || alt || ''} style={imgStyle} />
      </div>
    );
  }

  return <Placeholder />;
};

Image.propTypes = {
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
