import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Section, RichText } from 'components';

import './styles.scss';

const TitleFeatures = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, content, top_padding: topPadding, bottom_padding: bottomPadding } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasFeatures = items.length > 0;

  return (
    <Section className="title-features" topPadding={topPadding} bottomPadding={bottomPadding}>
      <div className="wrapper">
        {titleHtml && <div className="title-features-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <RichText html={contentHtml} className="title-features-content" h5 />}
        {hasFeatures && (
          <div className="title-features-container">
            {items.map((item, index) => {
              const { feature_icon: icon, feature_text: text } = item;
              return (
                <OnVisible className="title-features-feature" style={{ transitionDelay: `${index * 0.3}s` }}>
                  <Image className="title-features-feature-icon" image={icon} objectFit="contain" />
                  <div className="title-features-feature-text" dangerouslySetInnerHTML={{ __html: text.html }} />
                </OnVisible>
              );
            })}
          </div>
        )}
      </div>
    </Section>
  );
};

export default TitleFeatures;
