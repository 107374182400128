import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal, Video, Image, Label, Section } from 'components';
import playIcon from '../../images/play_button_circled.svg';

import './styles.scss';

const VideoSlice = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    tagline,
    tagline_icon: taglineIcon,
    text_placement: textPlacement,
    title,
    video,
    video_placeholder: placeholder,
    youtube_link: youtube,
    background_color: backgroundColor,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
    label_color: labelColor,
    show_overlay: showOverlay,
  } = primary;

  const youtubeUrl = youtube?.url;

  // content check
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasTaglineIcon = taglineIcon?.url;
  const hasCta = ctaLink?.url && ctaText?.text;

  const [showModal, setShowModal] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const hasVideo = video && video.url;
  const hasContent = titleHtml || contentHtml;
  const isTextContained = textPlacement === null || !hasContent || textPlacement;

  return (
    <Section
      className="videoSlice"
      sliceName="videoSlice"
      backgroundColor={backgroundColor}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      <div className={`wrapper ${!isTextContained && 'above'}`}>
        {hasContent && (
          <div className={`video-section-text-container ${!isTextContained && 'above'}`}>
            <div className={`video-section-tagline-container ${!isTextContained && 'above'}`}>
              {hasTaglineIcon && (
                <Image
                  className={`video-section-tagline-icon ${!isTextContained && 'above'}`}
                  image={taglineIcon}
                  objectFit="contain"
                />
              )}
              {taglineHtml && isTextContained && (
                <div
                  className={`video-section-tagline ${!isTextContained && 'above'}`}
                  dangerouslySetInnerHTML={{ __html: taglineHtml }}
                />
              )}
              {taglineHtml && !isTextContained && <Label labelHtml={taglineHtml} theme={labelColor} />}
            </div>
            {titleHtml && (
              <div
                className={`video-section-title ${!isTextContained && 'above'}`}
                dangerouslySetInnerHTML={{ __html: titleHtml }}
              />
            )}
            {contentHtml && <div className="video-section-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
            {hasCta && (
              <Button className="videoButton" to={ctaLink.url} theme={`${isTextContained ? 'inverted' : 'primary'}`}>
                {ctaText.text}
              </Button>
            )}
            {!hasCta && youtubeUrl && ctaText?.text && (
              <Button className="videoButton" onClick={openModal} theme={`${isTextContained ? 'inverted' : 'primary'}`}>
                {ctaText.text}
              </Button>
            )}
          </div>
        )}

        {hasVideo && (
          <div
            role="img"
            onClick={openModal}
            className={`video-section-video-container ${!isTextContained && 'above'} ${
              !showOverlay && 'removeOverlay'
            }`}
          >
            <Video
              key="Particular Audience"
              id="particular-audience"
              placeholder={placeholder?.gatsbyImageData?.base64}
              src={hasVideo}
              addOverlay={!isTextContained}
              overlayOnClick={() => openModal}
            />
            {showOverlay && (
              <a href="#openmodal" className="video-section-play" onClick={openModal}>
                {!hasContent && <Image className="play-button" image={{ url: playIcon }} />}
              </a>
            )}
          </div>
        )}

        {placeholder?.url && !hasVideo && (
          <Image
            image={{ url: placeholder?.url }}
            className={`video-section-video-container ${!isTextContained && 'above'}`}
          />
        )}
      </div>

      {youtubeUrl && (
        <Modal active={showModal} closeModal={closeModal} className="iframe-modal">
          <div className="iframe-container">
            <ReactPlayer
              url={`${youtubeUrl} || https://www.youtube.com/watch?v=2yoLpAJUL14&feature=youtu.be&ab_channel=JamesTaylor`}
              volume={1}
              muted={false}
              width="100%"
              height="100%"
              config={{
                youtube: {
                  playerVars: { autoplay: 1, controls: 1 },
                },
              }}
            />
          </div>
        </Modal>
      )}
    </Section>
  );
};

export default VideoSlice;
