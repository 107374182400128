import React, { useState, useEffect } from 'react';
import Sticky from 'react-sticky-el';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Image, Button } from 'components';
import chevronCircleActive from '../../images/chevron_circle_primary.svg';
import chevronCircleGrey from '../../images/chevron_circle_grey.svg';
import magnifyingGlass from '../../images/magnifier.svg';
import './styles.scss';

const getIdFromTitle = title => (title ? title.replace(/\s+/g, '-').toLowerCase() : '');

const TableOfContents = props => {
  const { anchorPoints } = props;

  const links = anchorPoints.map(label => ({ id: getIdFromTitle(label), label }));

  const [active, setActive] = useState(null);
  const [linkElements, setLinkElements] = useState(links);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    // Fetch offsetTop values for elements matching sidebar ids
    getLinkOffsets();
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  // logic here about changing chevron to red on active
  const getLinkOffsets = () => {
    // Loop over sidebar items and get the offsetTop of the related divs
    anchorPoints.forEach((anchorPoint, index) => {
      const anchorId = getIdFromTitle(anchorPoint);
      const linkElement = document.getElementById(anchorId);
      const offset = window.pageYOffset + linkElement.getBoundingClientRect().top;
      linkElements[index].offset = linkElement ? offset : 9999;
    });
    // Save sideLinks with offset keys to state
    setLinkElements(linkElements);
    setActive(active || linkElements[0]);
  };

  const scrollListener = () => {
    const scrollPosition = window.pageYOffset;
    const top = scrollPosition;
    const bottomOfPage = scrollPosition + window.innerHeight === document.documentElement.scrollHeight;
    const lastItem = linkElements[linkElements.length - 1];

    const getActive = (link, index, self) => {
      // First sidebar link selected when scroll is less than first item offset
      if (index === 0 && top < link.offset) return true;
      // Last sidebar link selected when check reaches end of array
      if (!self[index + 1]) return true;
      // Check whether top falls between active and next item's offset
      return top >= link.offset && top <= self[index + 1].offset;
    };

    const scrollActive = bottomOfPage ? lastItem : linkElements.find(getActive);

    if (scrollActive && scrollActive !== active) {
      setActive(scrollActive);
    }
  };

  const handleClick = (event, anchorId) => {
    event.preventDefault();
    const anchorElement = document.getElementById(`${anchorId}`);
    scrollIntoView(anchorElement, { behavior: 'smooth', block: 'start' });
  };

  return (
    <Sticky
      stickyClassName="sticky"
      boundaryElement=".blog-post-content-container"
      hideOnBoundaryHit={false}
      className="toc"
    >
      {/* <div className="toc-header">
        <Image image={{ url: magnifyingGlass }} />
        <span className="toc-title">Table of contents</span>
      </div>
      <div className="toc-anchor-titles">
        {anchorPoints.map(anchorPoint => {
          const anchorId = getIdFromTitle(anchorPoint);
          const isActive = active && active.label === anchorPoint;
          return (
            <a
              key={anchorId}
              href={`#${anchorId}`}
              className={`toc-anchor-container ${isActive ? 'active' : 'inactive'}`}
              onClick={event => handleClick(event, anchorId)}
            >
              <div key={anchorPoint} dangerouslySetInnerHTML={{ __html: anchorPoint }} className="toc-anchor-title" />
              <Image
                image={{ url: isActive ? chevronCircleActive : chevronCircleGrey }}
                className="toc-title-chevron"
              />
            </a>
          );
        })}
      </div> */}
      <span className="toc-cta-title">Make your website intuitive</span>
      <p>Discover the Particular Audience platform.</p>
      <Button to="/contact" className="toc-cta-link">
        Learn more
      </Button>
    </Sticky>
  );
};

export default TableOfContents;
