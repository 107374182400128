import React from 'react';
import './styles.scss';

const AnchorPoint = props => {
  const { data } = props;
  const { primary } = data;
  const { anchor_title: title } = primary;

  const titleHtml = title?.html;
  const titleText = title?.text;

  const anchorId = titleText ? titleText.replace(/\s+/g, '-').toLowerCase() : '';

  return (
    <div className="anchor-point" id={anchorId}>
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: title.html }} className="anchor-point-title"></div>}
    </div>
  );
};

export default AnchorPoint;
