import React from 'react';
import { Link } from 'gatsby';
import { Image } from 'components';
import { truncateString } from '../../utils/helpers';

import './styles.scss';

const BlogCard = (props) => {
  const { data, className, uid: slug, showExcerpt } = props;
  if (!data) return null;

  const { title, hero_image: heroImage, category, excerpt, timestamp, author } = data;
  const { uid: categoryName } = category;

  if (!categoryName) return null;
  // if (!cardButton) return null;

  const formattedCategory = categoryName.charAt(0).toUpperCase() + categoryName.slice(1).replace(/-/g, ' ');

  const titleText = title?.text;
  const hasImage = heroImage?.url;
  const hasCategory = category?.uid;
  const excerptText = excerpt?.text;
  const authorText = author?.text;

  // rendering variant
  const cardLink = `/${slug}`;
  const truncatedExcerpt = truncateString(excerptText, 200);

  return (
    <section className={`blog-post-card ${className || ''}`}>
      <div className="blog-post-card-container">
        {hasImage && (
          <Link to={cardLink} className="blog-post-card-image-container">
            <Image image={heroImage} className="image-box" />
          </Link>
        )}
        <div className="blog-post-card-text-container">
          <Link to={`/${category.uid}`} className="blog-post-card-category">
            {hasCategory && <span>{formattedCategory}</span>}
          </Link>
          {titleText && (
            <Link to={cardLink} className="blog-post-card-title">
              <span>{titleText}</span>
            </Link>
          )}
          {excerptText && showExcerpt && (
            <div dangerouslySetInnerHTML={{ __html: truncatedExcerpt }} className="blog-post-card-excerpt" />
          )}
          {(timestamp || authorText) && (
            <div className="blog-post-card-time-author">
              {timestamp && <span>{timestamp}</span>}
              <span> &nbsp; | &nbsp; </span>
              {authorText && <span>{authorText}</span>}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlogCard;
