import React from 'react';
import { Link } from 'components';
import './styles.scss';

const BlogPostHeader = props => {
  // data
  const { title, category, timestamp, author } = props;

  // data formatting
  const titleHtml = title?.html;
  const authorText = author?.text;
  const { uid: categoryName } = category;
  const formattedCategory = categoryName && categoryName.toUpperCase().replace(/-/g, ' ');
  // time to read

  return (
    <section className="blog-post-header">
      <div className="wrapper">
        {formattedCategory && (
          <Link to={`/${categoryName}`}>
            <span className="blog-post-category">{formattedCategory}</span>
          </Link>
        )}
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className="blog-post-title"></div>}
        <div className="blog-post-header-details">
          {timestamp && (
            <span className="blog-post-time-stamp">{`Published ${timestamp}${
              authorText ? ` by ${authorText}` : ''
            }`}</span>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlogPostHeader;
