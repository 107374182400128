export const truncateString = (string, maxLength = 50) => {
  if (!string) return null;
  if (string.length <= maxLength) return string;
  return `${string.substring(0, maxLength)}...`;
};

export const getLinkTo = link => {
  if (!link) return null;
  if (link.url) return link.url;
  if (link.slug) return `/${link.slug}/`;
  return null;
};

export const generatePath = slug => {
  if (slug === 'home') return '/';
  const formmatedSlug = slug.replace(/\./g, '/');
  if (slug.indexOf('staging') !== -1) return `/dev/${formmatedSlug}/`;
  return `/${formmatedSlug}/`;
};
