import React from 'react';
import OnVisible from 'react-on-visible';
import { useStaticQuery, graphql } from 'gatsby';
import { Image, Button } from 'components';
import './styles.scss';

// header links on settings query
const sliceQuery = graphql`
  query {
    ...ProductGridSettingsFragment
  }
`;

const ProductGrid = (props) => {
  const { prismicSettings } = useStaticQuery(sliceQuery);
  const { data } = prismicSettings;
  const { body } = data;
  const sliceData = body[0];
  const { primary, items } = sliceData;
  const { title } = primary;

  // content checks
  const titleText = title?.text;

  return (
    <OnVisible className="product-grid" wrappingElement="section">
      <div className="wrapper">
        {titleText && (
          <div className="product-grid-title">
            <h3>{titleText}</h3>
          </div>
        )}
        <div className="product-grid-container">
          {items.map((item, index) => {
            const { cta_link: ctaLink, cta_text: ctaText, content, icon, title: featureTitle } = item;

            // content checks
            const hasCta = ctaLink && ctaText.text;
            const contentHtml = content?.html;
            const featureTitleHtml = featureTitle?.html;
            const featureTitleText = featureTitle?.text;
            return (
              <div className="product-grid-cell" style={{ transitionDelay: `${index * 0.3}s` }} key={featureTitle.text}>
                {icon?.url && <Image className="product-grid-cell-image" image={icon} objectFit="contain" />}
                {featureTitleHtml && (
                  <div className="product-grid-cell-title">
                    <h5>{featureTitleText}</h5>
                  </div>
                )}
                {contentHtml && (
                  <div className="product-grid-cell-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
                )}
                {hasCta && <Button to={ctaLink.url}>{ctaText.text}</Button>}
              </div>
            );
          })}
        </div>
      </div>
    </OnVisible>
  );
};

export default ProductGrid;
