/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const RichText = React.forwardRef(
  ({ html, h1, h2, h3, h4, h5, h6, p1, p2, p3, className, subheading, style, richTextRef, as }, ref) => {
    if (!html || html.length < 7) return null;

    const refToUse = richTextRef || ref;

    const combinedClassNames = classNames('rich-text', className, {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p1,
      p2, // used for rich text
      subheading,
    });

    function prepHtml(code) {
      const cleanedHtml = code.replace(/target="undefined"/g, '');
      const processedHtml = cleanedHtml.replace(/<p><\/p>/g, '<br>');
      return processedHtml;
    }

    const preppedHtml = prepHtml(html);

    const Tag = as || 'div';

    return (
      <Tag
        className={combinedClassNames}
        dangerouslySetInnerHTML={{ __html: preppedHtml }}
        ref={refToUse || null}
        style={style || {}}
      />
    );
  }
);

export default RichText;
