import React, { useState } from 'react';
import { Image, Modal, Button, Label, Section, Video, RichText } from 'components';
import ReactPlayer from 'react-player';

import './styles.scss';

function isVideoURL(mediaURL) {
  if (!mediaURL) return false;
  const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv'];
  const urlParts = mediaURL.split('.');
  const fileExtension = urlParts[urlParts.length - 1].toLowerCase();

  return videoExtensions.includes(`.${fileExtension}`);
}

const Header = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    background_color: backgroundColor,
    content,
    label,
    primary_cta_link: primaryCtaLink,
    primary_cta_text: primaryCtaText,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_text: secondaryCtaText,
    title,
    video,
    image_size: isImageFullSize,
    image_object_fit: objectFit,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
    header_media: headerMedia,
    label_color: labelColor,
  } = primary;

  const [showModal, setShowModal] = useState(false);

  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const hasButton =
    (primaryCtaLink?.url && primaryCtaText?.text) ||
    (secondaryCtaLink?.url && secondaryCtaText?.text) ||
    video?.embed_url;

  const isVideo = isVideoURL(headerMedia?.url);

  return (
    <Section
      className="headerSlice"
      containerClassName={`headerContainer ${isImageFullSize ? 'fullSize' : 'contained'}`}
      sliceName="headerSlice"
      topPadding={topPadding}
      bottomPadding={bottomPadding}
      backgroundColor={backgroundColor}
    >
      <div className={`wrapper ${isImageFullSize ? 'fullSize' : 'contained'} ${!headerMedia?.url && 'noMedia'}`}>
        <div className={`textContainer ${isImageFullSize && 'fullSizeImage'}`}>
          {label?.text && <Label labelHtml={label?.html} theme={labelColor} />}
          <div className="title">{title?.text}</div>
          {content?.html && <RichText className="content" html={content?.html} />}
          {hasButton && (
            <div className="buttonsContainer">
              {primaryCtaLink?.url && primaryCtaText?.text && (
                <Button theme="primary" removeArrows className="primaryButton" to={primaryCtaLink?.url}>
                  {primaryCtaText?.text}
                </Button>
              )}
              {secondaryCtaLink?.url && secondaryCtaText?.text && (
                <Button className="secondaryButton" to={secondaryCtaLink?.url}>
                  {secondaryCtaText?.text}
                </Button>
              )}
              {video?.embed_url && (
                <div className="playButton">
                  <Button onClick={openModal} theme="play">
                    Watch video
                  </Button>
                </div>
              )}
            </div>
          )}
          <Modal active={showModal} closeModal={closeModal} className="iframe-modal">
            <div className="iframe-container">
              <ReactPlayer
                url={video?.embed_url}
                volume={1}
                muted={false}
                config={{
                  youtube: {
                    playerVars: { autoplay: 1, controls: 1 },
                  },
                }}
              />
            </div>
          </Modal>
        </div>
        <div className={`mediaContainer ${isImageFullSize ? 'fullSize' : 'contained'}`}>
          {isVideo && <Video id={headerMedia.url} src={headerMedia.url} objectFit={objectFit} />}
          {!isVideo && headerMedia?.url && <Image imgStyle={{ objectFit }} image={{ url: headerMedia?.url }} />}
        </div>
      </div>
    </Section>
  );
};

export default Header;
