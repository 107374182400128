import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Section } from 'components';

import './styles.scss';

const CardGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    title,
    content,
    background_color: backgroundColor,
    number_of_columns: colNumber,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  // classes
  const columnNumber = colNumber === '2' ? 'two-columns' : ''; // three cols default

  return (
    <Section
      className={`card-grid ${columnNumber}`}
      backgroundColor={backgroundColor}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
    >
      <div className="wrapper">
        <div className="card-grid-header">
          {titleHtml && <div className="card-grid-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {contentHtml && <div className="card-grid-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        </div>
        <div className="card-grid-container">
          {items.map((item, index) => {
            const { card_content: cardContent, card_icon: icon, card_title: cardTitle } = item;

            return (
              <OnVisible
                className="card-grid-feature"
                style={{ transitionDelay: `${index * 0.2}s` }}
                key={cardTitle.text}
              >
                {icon?.url && <Image className="card-grid-feature-icon" image={icon} objectFit="contain" />}
                <div className="card-grid-feature-title" dangerouslySetInnerHTML={{ __html: cardTitle.html }} />
                <div className="card-grid-feature-content" dangerouslySetInnerHTML={{ __html: cardContent.html }} />
              </OnVisible>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default CardGrid;
