import React from 'react';
import PostGrid from '../PostGrid/index.jsx';
import './styles.scss';

const CategoryPosts = props => {
  const { posts, title } = props;

  return (
    <section className="category-posts">
      <PostGrid posts={posts} title={title} showExcerpt />
    </section>
  );
};

export default CategoryPosts;
