import React from 'react';
import { Image } from 'components';

import './styles.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = (props) => {
  const {
    as,
    className,
    containerClassName,
    id,
    containerId,
    children,
    sliceName,
    noContainer,
    bgImage,
    topPadding,
    bottomPadding,
    largeContainer,
    backgroundColor,
  } = props;

  const HtmlElement = as || 'section';

  const spacingClassNames = {
    none: 'noSpacing',
    s: 'smallSpacing',
    m: 'mediumSpacing',
    l: 'largeSpacing',
  };

  return (
    <HtmlElement
      className={`sectionComponent top-${spacingClassNames[topPadding]} bottom-${spacingClassNames[bottomPadding]} 
        ${className || ''} ${backgroundColor && backgroundColor.toLowerCase()}`}
      id={id || null}
      data-slice={isDev ? sliceName : null}
    >
      {bgImage && <Image image={bgImage} className="sectionBg" />}
      {noContainer ? (
        children
      ) : (
        <div
          className={`container ${largeContainer ? 'largeContainer' : ''} ${containerClassName || ''}`}
          id={containerId || null}
        >
          {children}
        </div>
      )}
    </HtmlElement>
  );
};

export default Section;
