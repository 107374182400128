import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import { Image, Section } from 'components';
import chevron from '../../images/black_chevron.svg';
import './styles.scss';

const FeatureCarousel = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, title, top_padding: topPadding, bottom_padding: bottomPadding } = primary;

  // primary content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  // state
  const [activeIndex, setActiveIndex] = useState(0);

  const handleOnClick = (event, index) => {
    event.preventDefault();
    setActiveIndex(index);
  };

  return (
    <Section className="feature-carousel" topPadding={topPadding} bottomPadding={bottomPadding}>
      <OnVisible className="wrapper">
        <div className="feature-carousel-header">
          {titleHtml && <div className="feature-carousel-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {contentHtml && (
            <div className="feature-carousel-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
          )}
        </div>
        <div className="feature-carousel-container">
          {/* FEATURE DESCRIPTIONS */}
          <div className="feature-carousel-features">
            {items.map((item, index) => {
              const {
                feature_description: featureDescription,
                feature_title: featuretitle,
                // feature_image: featureImage,
              } = item;
              // content checks
              const descriptionHtml = featureDescription?.html;
              const featTitleHtml = featuretitle?.html;
              const featTitleText = featuretitle?.text;
              const isActive = index === activeIndex;

              return (
                <button
                  className={`feature-carousel-feature ${isActive ? 'active' : 'inactive'}`}
                  onClick={(event) => handleOnClick(event, index)}
                  key={featTitleText}
                  type="button"
                >
                  <div className="feature-carousel-title-chevron">
                    {featTitleHtml && (
                      <div
                        className="feature-carousel-feature-title"
                        dangerouslySetInnerHTML={{ __html: featTitleHtml }}
                      />
                    )}
                    <Image className="feature-carousel-chevron" image={{ url: chevron }} />
                  </div>
                  {descriptionHtml && (
                    <div
                      className="feature-carousel-feature-description"
                      dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                    />
                  )}
                </button>
              );
            })}
          </div>
          {items.map((item, index) => {
            const { feature_image: featureImage, featuretitle } = item;
            const featTitleText = featuretitle?.text;
            const isActive = index === activeIndex;
            if (!isActive || !featureImage?.url) return null;
            return (
              <div className="feature-carousel-custom" key={featTitleText}>
                <Image image={featureImage} className="feature-carousel-image" placeholder="NONE" />
              </div>
            );
          })}
        </div>
      </OnVisible>
    </Section>
  );
};

export default FeatureCarousel;
