import React, { useEffect, useState } from 'react';
import './styles.scss';

const ScrollBar = () => {
  // state
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    // Fetch offsetTop values for elements matching sidebar ids
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollWidth]);

  const scrollListener = () => {
    const scrollPosition = window.pageYOffset;
    const lengthOfScroll = document.documentElement.scrollHeight - window.innerHeight;
    const percentOfCurrentScroll = (scrollPosition / lengthOfScroll) * 100;
    const roundedPercent = percentOfCurrentScroll.toFixed(0);
    if (roundedPercent < 2) {
      setScrollWidth(roundedPercent);
    }
    if (roundedPercent > 98) {
      setScrollWidth(roundedPercent);
    }
    if (roundedPercent - scrollWidth > 3) {
      setScrollWidth(roundedPercent);
    }
    if (scrollWidth - roundedPercent > 3) {
      setScrollWidth(roundedPercent);
    }
  };

  return <div className="scroll-bar" style={{ width: `${scrollWidth}%` }} />;
};

export default ScrollBar;
