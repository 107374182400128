import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Section } from 'components';

import './styles.scss';

const FeatureGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, content, top_padding: topPadding, bottom_padding: bottomPadding } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  return (
    <Section className="feature-grid" topPadding={topPadding} bottomPadding={bottomPadding}>
      <div className="wrapper">
        {titleHtml && <div className="feature-grid-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <div className="feature-grid-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        <div className="feature-grid-container">
          {items.map((item, index) => {
            const { feature_icon: icon, feature_title: featureTitle, feature_content: featureContent } = item;

            return (
              <OnVisible className="feature-grid-feature" style={{ transitionDelay: `${index * 0.2}s` }}>
                <div className="feature-grid-feature-header">
                  {icon?.url && <Image className="feature-grid-feature-icon" image={icon} objectFit="contain" />}
                  <div className="feature-grid-feature-title" dangerouslySetInnerHTML={{ __html: featureTitle.html }} />
                </div>
                <div
                  className="feature-grid-feature-content"
                  dangerouslySetInnerHTML={{ __html: featureContent.html }}
                />
              </OnVisible>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default FeatureGrid;
