import React from 'react';
import { Image, SearchBar, Link, Section } from 'components';
import checkbox from '../../images/new_checkbox.svg';
import './styles.scss';

const Search = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    tagline,
    tagline_icon: taglineIcon,
    title,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    background_colour: backgroundColor,
    exclude_search: excludeSearch,
    center_content: centerContent,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
  } = primary;

  // content checks
  const titleText = title?.text;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasTaglineIcon = taglineIcon?.url;
  const hasCta = ctaLink?.url && ctaText?.text;

  // classes
  const isCentered = centerContent || excludeSearch ? 'centered' : '';

  return (
    <Section className={`search ${isCentered}`} topPadding={topPadding} bottomPadding={bottomPadding}>
      <div className="wrapper">
        <div className="search-bg" style={{ backgroundColor }}>
          <div className="search-text-container">
            {/* TAGLINE */}
            {taglineHtml && (
              <div className="search-tagline-container">
                {hasTaglineIcon && <Image className="search-tagline-icon" image={taglineIcon} objectFit="contain" />}
                {taglineHtml && <div className="search-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
              </div>
            )}
            {/* TITLE, CONTENT, CTA */}
            {titleText && (
              <div className="search-title">
                <h4>{titleText}</h4>
              </div>
            )}
            {contentHtml && <div className="search-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
          </div>
          {/* MOCK SEARCHBAR */}
          <div className="search-bar-section">
            {!excludeSearch && <SearchBar />}
            <div className="search-features">
              {items.map((item) => {
                const { feature } = item;
                return (
                  <div className="search-feature-container" key={feature?.text}>
                    <Image className="search-feature-checkbox" image={{ url: checkbox }} />
                    <div className="search-feature" dangerouslySetInnerHTML={{ __html: feature.html }} />
                  </div>
                );
              })}
            </div>
            {/* CTA */}
            {hasCta && (
              <Link className="search-cta button filled" to={ctaLink.url}>
                {ctaText.text}
              </Link>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Search;
