/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Hamburger, Logo, Link, Image, MegaNav, Button } from 'components';
import chevron from '../../images/black_chevron.svg';

import './styles.scss';

const Header = () => {
  const [showNavBurger, setShowNavBurger] = useState(false);
  const [scrollTop, setScrollTop] = useState(0); // set pixels to - from top when shownav = false
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true
  const [activeMobileNavItem, setActiveMobileNavItem] = useState('none');

  // toggle main nav links for mobile
  const toggleNav = (event) => {
    event.preventDefault();
    setShowNavBurger(!showNavBurger);
    setActiveMobileNavItem('none');
  };

  // C L O S E   O N   E S C   A N D   B O D Y   L O C K

  const handleEscKey = (event) => {
    // get rid of nav on esc keydown
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNavBurger(false);
    }
  };

  const lockBody = () => {
    // lock body while show nav true
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when show nav false, unlock body
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  const updateActiveMobileNavItem = (navItem) => {
    setActiveMobileNavItem(navItem);
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // use effect when shownav updated
  useEffect(() => {
    if (showNavBurger && !bodyLocked) lockBody();
    if (!showNavBurger && bodyLocked) unlockBody();
  }, [showNavBurger]);

  // toggle visibility for mobile
  const hamburgerOpenClass = showNavBurger ? 'hamburger-open' : '';

  return (
    <header className={`header ${hamburgerOpenClass}`}>
      <div className="wrapper" id="headerWrapper">
        {activeMobileNavItem !== 'none' ? (
          <Link className="mobileNavTitle" onClick={() => setActiveMobileNavItem('none')}>
            <Button theme="minimal" removeArrows className="navTitleBackButton">
              <Image image={{ url: chevron }} />
            </Button>
            <div>{activeMobileNavItem}</div>
          </Link>
        ) : (
          <Logo className="mobileLogo" />
        )}
        <Logo className="desktopLogo" />
        <div className="links-and-search">
          <Hamburger onClick={toggleNav} active={showNavBurger} />
          <MegaNav
            classNamePrefix="header"
            onMobileNavItemClick={updateActiveMobileNavItem}
            activeMobileNavItem={activeMobileNavItem}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
