import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Section } from 'components';

import './styles.scss';

const Testimonial = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    author,
    author_position: position,
    image,
    tagline,
    tagline_icon: taglineIcon,
    testimonial,
    top_padding: topPadding,
    bottom_padding: bottomPadding,
  } = primary;

  // content checks
  const positionHtml = position?.html;
  const authorHtml = author?.html;
  const taglineHtml = tagline?.html;
  const hasTaglineIcon = taglineIcon?.url;
  const hasImage = image?.url;
  const testimonialHtml = testimonial?.html;

  return (
    <Section className="testimonial" topPadding={topPadding} bottomPadding={bottomPadding}>
      <div className="wrapper">
        <OnVisible className="testimonial-text-container">
          <div className="testimonial-tagline-container">
            {hasTaglineIcon && <Image className="testimonial-tagline-icon" image={taglineIcon} objectFit="contain" />}
            {taglineHtml && <div className="testimonial-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
          </div>
          {testimonialHtml && (
            <OnVisible className="testimonial-quote" dangerouslySetInnerHTML={{ __html: testimonialHtml }} />
          )}
          {authorHtml && <div className="testimonial-author" dangerouslySetInnerHTML={{ __html: authorHtml }} />}
          {positionHtml && <div className="testimonial-position" dangerouslySetInnerHTML={{ __html: positionHtml }} />}
        </OnVisible>
        <OnVisible className="testimonial-image-wrapper">
          {hasImage && <Image className="testimonial-image" image={image} />}
        </OnVisible>
      </div>
    </Section>
  );
};

export default Testimonial;
