import React from 'react';
import { Link, Logo } from 'components';
import './styles.scss';

const Footer = () => (
  <footer className="footer">
    <div className="wrapper">
      <Logo />
      <div className="sitemap">
        <span className="legal">© Copyright {new Date().getFullYear()}</span>
        <p>
          Icons by{' '}
          <a className="iconsCite" href="https://icons8.com">
            Icons8
          </a>
        </p>
        <div className="links">
          <Link to="/privacy">Privacy</Link>
          <Link to="/terms">Terms</Link>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
